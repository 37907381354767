import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import { InitSentry } from './sentry'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { getNewToken } from './Controllers/OAuth/OAuthApi'
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
    mutations: {
      retryDelay: (attempt) => Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
      retry: async (failureCount, error) => {
        const { message } = error
        const messageObj = JSON.parse(message)
        switch (messageObj.status) {
          case 403:
          case 401:
            const renewResult = await getNewToken()
            if (!renewResult.ok) {
              localStorage.clear()
              queryClient.invalidateQueries({ refetchType: 'none' })
              queryClient.clear()
              window.location.assign('/login')
              return false
            }

            const renewInfo = await renewResult.json()
            if (!renewInfo?.encodedToken) {
              localStorage.clear()
              queryClient.invalidateQueries({ refetchType: 'none' })
              queryClient.clear()
              window.location.assign('/login')
              return false
            }

            localStorage.setItem('token', renewInfo.encodedToken)
            return true
          default:
            return failureCount < 4
        }
      },
    },
  },
})
InitSentry()

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </QueryClientProvider>,
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
