import React from 'react'
import { Navigate } from 'react-router-dom'

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const Order = React.lazy(() => import('./views/Orders/Order'))
const OrderEdit = React.lazy(() => import('./views/Orders/OrderEditManager'))
const OrderRestart = React.lazy(() => import('./views/Orders/RestartOrder/RestartOrderManager'))
const PauseOrder = React.lazy(() => import('./views/Orders/PauseOrder/PauseOrderManager'))
const Contacts = React.lazy(() => import('./views/Contacts/Contacts'))
const Invoices = React.lazy(() => import('./views/Invoices/Invoices'))
const InvoiceDetails = React.lazy(() => import('./views/Invoices/InvoiceDetails'))
const Client = React.lazy(() => import('./views/Client/Client'))
const Workplaces = React.lazy(() => import('./views/Workplaces/Workplaces'))
const WorkplaceDetails = React.lazy(() => import('./views/Workplaces/WorkplaceDetails'))
const AddWorkplaces = React.lazy(() => import('./views/Workplaces/AddWorkplaces'))
const WorkplaceEdit = React.lazy(() => import('./views/Workplaces/WorkplaceEdit'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Region = React.lazy(() => import('./views/pages/regions/Regions'))

const routes = [
  { path: '/region', disableWrapping: true, name: 'Region', element: Region },
  { path: '/orders', name: 'Order', element: Order },
  { path: '/workplace/:workplaceId/order/:orderNo', name: 'Order Details', element: OrderEdit },
  {
    path: '/workplace/:workplaceId/restart/:orderNo',
    name: 'Order Details',
    element: OrderRestart,
  },
  {
    path: '/workplace/:workplaceId/pause/:orderNo',
    name: 'Order Details',
    element: PauseOrder,
  },
  { path: '/contacts', name: 'Contacts', element: Contacts },
  { path: '/invoices', name: 'Invoices', element: Invoices },
  { path: '/invoices/:id', name: 'Invoice Details', element: InvoiceDetails },
  { path: '/info', name: 'Info', element: Client },
  { path: '/workplaces', name: 'Workplace', element: Workplaces },
  { path: '/workplace/:id', name: 'Workplace', element: WorkplaceDetails },
  { path: '/workplace/add', name: 'Workplace', element: AddWorkplaces },
  { path: '/workplaces/edit/:id', name: 'Workplace', element: WorkplaceEdit },
  { path: '/register', disableWrapping: true, name: 'Register Page', element: Register },
  { path: '/404', disableWrapping: true, exact: true, name: 'Page 404', element: Page404 },
  { path: '/500', disableWrapping: true, exact: true, name: 'Page 500', element: Page500 },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '*', name: 'Default', element: () => <Navigate to="/dashboard" /> },
]

export default routes
