import _ from 'lodash'
import { getNewToken, renewToken } from 'src/Controllers/OAuth/OAuthApi'
// const fetch = require('fetch-retry')(global.fetch)

export const baseApiUrl = 'https://api.ffo-ca.bymobinteg.com'
// export const baseApiUrl = 'http://localhost:1234'

export const newApiUrl = 'https://node-api.ffo-ca.bymobinteg.com'
// export const newApiUrl = 'http://localhost:8080'
// export const testProductImage = 'https://node-api.ffo-ca.bymobinteg.com'
export const priceTestApiUrl = 'http://localhost:8080'

export const fetchRetry = require('fetch-retry')(global.fetch, {
  retryOn: [500, 503],
  retries: 3,
  retryDelay: (attempt) => Math.pow(2, attempt) * 1000,
})

export function getHttpHeaders(method) {
  let init = {
    method: method,
    headers: {
      Accept: '*/*',
      'Content-type': 'application/json',
    },
  }
  let token = localStorage.getItem('token')
  if (token) {
    init.headers.Authorization = 'Bearer ' + token
  }
  return init
}

export async function httpGetRequest(callbackMethod) {
  let response
  try {
    let response = await callbackMethod()
    try {
      if (response.ok) {
        return await response.json()
      }
      switch (response.status) {
        case 401:
          const renewResult = await getNewToken()
          if (!renewResult.ok) {
            localStorage.clear()
            window.location.reload()
            break
          }
          const renewInfo = await renewResult.json()
          if (!renewInfo?.encodedToken) {
            localStorage.clear()
            window.location.reload()
            break
          }
          localStorage.setItem('token', renewInfo.encodedToken)
          return httpGetRequest(callbackMethod)
        default:
          return response.text().then((text) => {
            throw new Error(text)
          })
      }
    } catch (e) {
      return response.text().then((text) => {
        throw new Error(text)
      })
    }
  } catch (err) {
    try {
      let renewResult = await renewToken()
      if (renewResult.Success) {
        response = await callbackMethod()
        if (response.ok) {
          return await response.json()
        } else {
          throw new Error('jwt expired')
        }
      }
    } catch (_err) {
      return response.text().then((text) => {
        throw new Error(text)
      })
    }
  }
}

export async function httpPostRequest(callbackMethod) {
  let response
  try {
    let response = await callbackMethod()

    try {
      return await response.json()
    } catch (e) {
      return response.text().then((text) => {
        throw new Error(text)
      })
    }
  } catch (err) {
    try {
      let renewResult = await renewToken()
      if (renewResult.Success) {
        response = await callbackMethod()
        if (response.ok) {
          return await response.json()
        } else {
          throw new Error('jwt expired')
        }
      }
    } catch (_err) {
      return response.text().then((text) => {
        throw new Error(text)
      })
    }
  }
}

export const DEFAULT_ERROR_MSG =
  'An unexpected error occurred. Please try again later or contact support.'

export const DEFAULT_ERROR_HANDLER = (setAlertComponent) => (error) => {
  if (!_.isEmpty(error?.message)) {
    try {
      error = JSON.parse(error.message)
    } catch (e) {
      setAlertComponent({
        open: true,
        severity: 'error',
        message: DEFAULT_ERROR_MSG,
      })
      return
    }
  }

  setAlertComponent({
    open: true,
    severity: 'error',
    message: _.get(error, 'error_message', _.get(error, 'message'), DEFAULT_ERROR_MSG),
  })
}

export const MAX_RETRY_ATTEMPTS = 2

export const handleErrorResponse = async (response, queryClient, queryInstance) => {
  const errorData = await response.json()
  const errorStatus = response.status

  if (queryInstance.failureCount > 0) {
    throw new Error(
      JSON.stringify({
        status: errorStatus,
        error_message: errorData.message || DEFAULT_ERROR_MSG,
      }),
    )
  }

  switch (errorStatus) {
    case 401:
    case 403: {
      const renewResult = await getNewToken()
      if (!renewResult.ok) {
        localStorage.clear()
        queryClient.invalidateQueries({ refetchType: 'none' })
        queryClient.clear()
        window.location.assign('/login')
      }

      const renewInfo = await renewResult.json()
      if (!renewInfo?.encodedToken) {
        localStorage.clear()
        queryClient.invalidateQueries({ refetchType: 'none' })
        queryClient.clear()
        window.location.assign('/login')
        return false
      }

      localStorage.setItem('token', renewInfo.encodedToken)
      if (queryInstance.failureCount == 0) {
        queryInstance.refetch()
      }

      break
    }
    default:
      throw new Error(
        JSON.stringify({
          status: errorStatus,
          error_message: errorData.message || DEFAULT_ERROR_MSG,
        }),
      )
  }
}

export const DEFAULT_RETRY_HANDLER = (queryClient, callback) => async (failureCount, error) => {
  const { message } = error

  if (failureCount > MAX_RETRY_ATTEMPTS) {
    return false
  }

  if (failureCount === MAX_RETRY_ATTEMPTS) {
    callback(error)
    return false
  }

  let messageObj
  try {
    messageObj = JSON.parse(message)
  } catch (e) {
    if (failureCount < MAX_RETRY_ATTEMPTS) {
      return true
    }

    callback(DEFAULT_ERROR_MSG)
    return false
  }

  switch (messageObj.status) {
    case 403:
    case 401:
      const renewResult = await getNewToken()
      if (!renewResult.ok) {
        localStorage.clear()
        queryClient.invalidateQueries({ refetchType: 'none' })
        queryClient.clear()
        window.location.assign('/login')
        return false
      }

      const renewInfo = await renewResult.json()
      if (!renewInfo?.encodedToken) {
        localStorage.clear()
        queryClient.invalidateQueries({ refetchType: 'none' })
        queryClient.clear()
        window.location.assign('/login')
        return false
      }

      localStorage.setItem('token', renewInfo.encodedToken)
      return failureCount < MAX_RETRY_ATTEMPTS
    default:
      return failureCount < MAX_RETRY_ATTEMPTS
  }
}
