import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import './scss/style.scss'
import '@coreui/coreui/dist/css/coreui.min.css'
import '@coreui/coreui-pro/dist/css/coreui.min.css'
import * as Sentry from '@sentry/react'
import { CContainer } from '@coreui/react'

import routes from './routes'
import { Backdrop, CircularProgress } from '@mui/material'
import AlertComponent from './components/common/AlertComponent/AlertComponent'
import { getEmailFromToken } from './utils/commonUtils'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  DEFAULT_ERROR_HANDLER,
  DEFAULT_RETRY_HANDLER,
  getHttpHeaders,
  handleErrorResponse,
  newApiUrl,
} from './utils/apiUtils'
import MultiReportComponent from './components/common/MultiReportComponent/MultiReportComponent'
// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))

function App() {
  const navigate = useNavigate()
  const location = useLocation()
  const queryClient = useQueryClient()
  const [accounts, setAccounts] = useState([])
  const [viewAccount, setViewAccount] = useState({})
  const [loading, setLoading] = useState(false)
  const [changedRegion, setChangedRegion] = useState(false)
  const [openMultiReport, setOpenMultiReport] = useState(false)
  const [alertComponent, setAlertComponent] = useState({
    open: false,
    severity: 'warning',
    message: '',
  })

  const [userIsAuthenticated, setUserIsAuthenticated] = useState(
    localStorage.getItem('token') ? true : false,
  )
  const [shouldQueryAccounts, setShouldQueryAccounts] = useState(
    localStorage.getItem('token') ? true : false,
  )

  const Logout = useCallback(async () => {
    localStorage.clear()
    setUserIsAuthenticated(false)
    setAccounts([])
    setViewAccount({})
    setChangedRegion(false)
    setLoading(false)
    setShouldQueryAccounts(false)
    queryClient.invalidateQueries({ refetchType: 'none' })
    queryClient.clear()
    navigate('/login')
  }, [navigate, queryClient])

  const accountList = useQuery({
    retry: false,
    enabled:
      !!shouldQueryAccounts && location.pathname !== '/login' && location.pathname !== '/region',
    queryKey: ['accounts', { email: getEmailFromToken() }],
    queryFn: async ({ queryKey }) => {
      const [, { email }] = queryKey
      const init = getHttpHeaders('GET')
      const response = await fetch(`${newApiUrl}/api/accounts/${email}/all`, init)
      if (response.ok) {
        return response.json()
      } else {
        handleErrorResponse(response, queryClient, accountList).catch(
          DEFAULT_ERROR_HANDLER(setAlertComponent),
        )

        return undefined
      }
    },
    select: useCallback(
      (data) => {
        if (!data) {
          return []
        }
        const _accounts = [...(data?.Response?.Result?.data?.items || accounts)]
        const numberAccounts = data?.Response?.Result?.data?.count || accounts?.length
        if (!numberAccounts) {
          setAlertComponent({
            open: true,
            severity: 'error',
            message: 'No accounts associated with this email.',
          })
          Logout()
          return []
        }

        const updatedAccountList = _accounts.map((account) => {
          if (!account?.environmentalData?.data) {
            account.totalTrees = 0
            return account
          }
          account.totalTrees = account.environmentalData.data.reduce(
            (total, { trees }) =>
              total +
              trees.reduce((totalTrees, currentValue) => totalTrees + currentValue.value, 0),
            0,
          )
          return account
        })

        const storedAccountId = localStorage.getItem('account')
        if (!storedAccountId) {
          localStorage.setItem('account', updatedAccountList[0].fruitful_account_id)
        }

        shouldQueryAccounts && setShouldQueryAccounts(() => false)
        return updatedAccountList
      },
      [shouldQueryAccounts],
    ),
  })

  const accountWorkplaces = useQuery({
    retry: false,
    enabled: !!viewAccount?.fruitful_account_id && !accountList.isFetching,
    queryKey: ['workplaces', { accountId: viewAccount?.fruitful_account_id }],
    queryFn: async ({ queryKey }) => {
      const [, { accountId }] = queryKey
      const [region, account_id] = accountId.split('-')
      const init = getHttpHeaders('GET')
      const response = await fetch(
        `${newApiUrl}/api/workplaces?accountId=${account_id}&region=${region}&status=${[
          'Active',
          'Ended',
          'Terminated',
          'NotActive',
        ]}`,
        init,
      )
      if (response.ok) {
        return response.json()
      } else {
        handleErrorResponse(response, queryClient, accountWorkplaces).catch(
          DEFAULT_ERROR_HANDLER(setAlertComponent),
        )

        return undefined
      }
    },
    select: (data) => {
      const workplaces = [...data.Content.Result]
      const activeWorkplaces = workplaces.filter(({ orders }) =>
        orders.some(({ sostatus }) => ['Active', 'NotActive'].includes(sostatus)),
      )
      const inactiveWorkplaces = workplaces.filter(({ orders }) =>
        orders.every(({ sostatus }) => ['Terminated', 'Ended'].includes(sostatus)),
      )
      const activeSortedWorkplaces = activeWorkplaces.sort((workplaceA, workplaceB) =>
        workplaceA.name.localeCompare(workplaceB.name),
      )
      const inactiveSortedWorkplaces = inactiveWorkplaces.sort((workplaceA, workplaceB) =>
        workplaceA.name.localeCompare(workplaceB.name),
      )

      return [...activeSortedWorkplaces, ...inactiveSortedWorkplaces]
    },
  })

  const contactList = useQuery({
    retry: false,
    enabled: !!viewAccount?.fruitful_account_id && !accountList.isFetching,
    queryKey: ['contacts', { accountId: viewAccount?.fruitful_account_id }],
    queryFn: async ({ queryKey }) => {
      const [, { accountId }] = queryKey
      const [region, account_id] = accountId.split('-')
      const init = getHttpHeaders('GET')
      const response = await fetch(
        `${newApiUrl}/api/accounts/${account_id}/contacts?region=${region}`,
        init,
      )
      if (response.ok) {
        return response.json()
      } else {
        handleErrorResponse(response, queryClient, contactList).catch(
          DEFAULT_ERROR_HANDLER(setAlertComponent),
        )

        return undefined
      }
    },
    onError: (error) => {
      console.log(error)
      throw new Error(JSON.stringify(error))
    },
    select: (data) => {
      return data.Content.Result
    },
  })

  useEffect(() => {
    const accountId = localStorage.getItem('account')
    if (!accountId && !accountList.isFetched) {
      navigate('/login')
      return
    }
    if (accountId) {
      let account = {}
      if (accountList.data?.length) {
        const foundAccount = accountList.data.find(
          ({ fruitful_account_id }) => fruitful_account_id === accountId,
        )
        if (foundAccount) {
          account = { ...foundAccount }
          setViewAccount(account)
        } else {
          account = { ...accountList.data[0] }
        }
      } else {
        account.fruitful_account_id = accountId
      }
      setViewAccount(account)
    }
  }, [accountList.data])

  function handleOfficeChange(
    selectedAccount,
    currentPath = '/dashboard',
    listAccounts = accountList.data || accounts,
  ) {
    const account = listAccounts.find(
      ({ fruitful_account_id }) => fruitful_account_id === selectedAccount,
    )
    if (account) {
      setViewAccount(() => account)
      localStorage.setItem('account', selectedAccount)
      navigate(currentPath === 'workplace' ? '/workplaces' : currentPath)
    }
  }

  const confirmAccountSelection = (accountNo, listOfAccounts = []) => {
    const _accountList = listOfAccounts.length ? listOfAccounts : accountList.data
    const account = _accountList.find(
      ({ fruitful_account_id }) => fruitful_account_id === accountNo,
    )
    if (account) {
      setViewAccount(() => account)
      localStorage.setItem('account', account.fruitful_account_id)
      navigate('/dashboard')
    }
  }

  const myProps = {
    regionId: viewAccount?.fruitful_account_id?.split('-')[0],
    account: viewAccount,
    changedRegion: changedRegion,
    setChangedRegion: setChangedRegion,
    loading: loading,
    setLoading: setLoading,
    contactList: contactList.data,
    ListOfAccounts: accountList?.data?.length ? accountList.data : accounts,
    workplaceList: accountWorkplaces.data,
    setAlertComponent: setAlertComponent,
    confirmAccountSelection: confirmAccountSelection,
    Logout: Logout,
    handleOfficeChange: handleOfficeChange,
    accountList: accountList,
    setShouldQueryAccounts: setShouldQueryAccounts,
    accountWorkplaces: accountWorkplaces,
    contactsObj: contactList,
  }

  const setBackgroundColor = () => {
    const { pathname } = location
    if (pathname.includes('/invoices/')) {
      return '#ffffff'
    }
    return ''
  }

  const contentChildWrapper = (viewElement) => (
    <CContainer fluid style={{ backgroundColor: setBackgroundColor() }}>
      <Suspense>{viewElement}</Suspense>
    </CContainer>
  )

  const renderContentChild = (viewElement) => (
    <DefaultLayout
      //Header
      logout={Logout}
      accounts={accountList?.data?.length ? accountList.data : accounts}
      viewAccount={viewAccount}
      contactList={contactList.data}
      handleOfficeChange={handleOfficeChange}
      setOpenMultiReport={setOpenMultiReport}
    >
      {contentChildWrapper(viewElement)}
    </DefaultLayout>
  )
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)
  return (
    <>
      <Suspense>
        <SentryRoutes>
          {!userIsAuthenticated ? (
            <Route
              exact
              path="/login"
              name="Login Page"
              element={
                <Login
                  setAlertComponent={setAlertComponent}
                  setUserIsAuthenticated={setUserIsAuthenticated}
                  setAccountList={setAccounts}
                  confirmAccountSelection={confirmAccountSelection}
                  setShouldQueryAccounts={setShouldQueryAccounts}
                />
              }
            />
          ) : (
            <>
              {routes.map((route) => (
                <Route
                  key={route.name}
                  path={route.path}
                  name={route.name}
                  exact={route.exact || false}
                  element={
                    route.disableWrapping ? (
                      <route.element {...myProps} />
                    ) : (
                      renderContentChild(<route.element {...myProps} />)
                    )
                  }
                />
              ))}
            </>
          )}
        </SentryRoutes>
      </Suspense>
      <MultiReportComponent
        viewAccount={viewAccount}
        contactList={contactList.data}
        openMultiReport={openMultiReport}
        setOpenMultiReport={setOpenMultiReport}
      />
      <Backdrop
        sx={{ color: '#008848', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={
          accountList.isFetching ||
          loading ||
          contactList.isFetching ||
          accountWorkplaces.isFetching
        }
      >
        <CircularProgress color="success" />
      </Backdrop>
      <AlertComponent
        alertComponent={alertComponent}
        alignment={{ vertical: 'top', horizontal: 'center' }}
        setAlertComponent={setAlertComponent}
      />
    </>
  )
}

export default Sentry.withProfiler(App)
