import {
  baseApiUrl,
  fetchRetry,
  getHttpHeaders,
  httpGetRequest,
  httpPostRequest,
  newApiUrl,
} from 'src/utils/apiUtils'

export async function recoverPasswordApiCall(data) {
  return await httpPostRequest(recoverPasswordApiCallInfo.bind(this, data))
}

async function recoverPasswordApiCallInfo(data) {
  let init = getHttpHeaders('POST')
  init.body = JSON.stringify(data)
  let response = await fetch(newApiUrl + '/auth/recover', init)
  return response
}

export async function loginApiCall(data) {
  return await httpPostRequest(loginApiCallInfo.bind(this, data))
}

async function loginApiCallInfo(data) {
  let init = getHttpHeaders('POST')
  init.body = JSON.stringify(data)
  let response = await fetch(newApiUrl + '/auth/login', init)
  return response
}

export async function logoutApiCall() {
  return await httpGetRequest(logoutApiCallInfo.bind(this))
}

async function logoutApiCallInfo() {
  let init = getHttpHeaders('GET')
  let response = await fetchRetry(baseApiUrl + '/logout', init)
  return response
}

let refreshPromise

export async function renewToken() {
  if (refreshPromise) {
    return refreshPromise
  } else {
    refreshPromise = new Promise((resolve, reject) => {
      getNewToken()
        .then(async (response) => {
          if (response.ok) {
            await response.json().then((result) => {
              localStorage.setItem('token', result.encodedToken)
              refreshPromise = undefined
              resolve(result)
            })
          } else {
            refreshPromise = undefined
            reject(response)
          }
        })
        .catch((err) => {
          console.log(err)
          refreshPromise = undefined
          reject(err)
        })
    })
    return refreshPromise
  }
}

export async function getNewToken() {
  let init = getHttpHeaders('POST')
  init.body = JSON.stringify({ token: localStorage.getItem('token') })
  return await fetch(newApiUrl + '/auth/refresh', init)
}
