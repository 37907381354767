import * as Sentry from '@sentry/react'
import React from 'react'
import { BrowserTracing } from '@sentry/tracing'
import { Routes } from 'react-router-dom'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

// Setup Sentry Error Reporting

export const InitSentry = () => {
  // Disable Sentry in development
  if (process.env.NODE_ENV === 'development') {
    return
  }

  Sentry.init({
    dsn: 'https://fab6fbefb85e42b98c6de3bba017d783@o4504599098425345.ingest.sentry.io/4504605296820224',
    environment: window.location.host,

    // Sample only a small percentage of user traces
    tracesSampleRate: 0.01,
    replaysSessionSampleRate: 0.01,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 0.2,

    integrations: [
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
      new BrowserTracing({
        // Propagate tracing id's to our API's
        tracePropagationTargets: [
          'localhost',
          'fruitydev.fruitfuloffice.co.uk',
          'api.ffo-ca.bymobinteg.com',
        ],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
  })
}

export const sentryCreateBrowserRouter = () => Sentry.withSentryReactRouterV6Routing(Routes)
