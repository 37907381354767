import moment from 'moment'
import _ from 'lodash'

export const weekdaysHelper = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
}

export const numberToWeekDayHelper = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
}

export const daysOfWeek = {
  Monday: 'Mon',
  Tuesday: 'Tue',
  Wednesday: 'Wed',
  Thursday: 'Thu',
  Friday: 'Fri',
}

export const regionMap = {
  DEV: 'LONDON',
  MID: 'MIDLANDS',
}

export const deliveryFrequency = {
  1: 'Every week',
  2: 'Every Two weeks',
  3: 'Every Three weeks',
  4: 'Every Four weeks',
  5: 'Every Five weeks',
  100: 'First in Month',
  102: 'Last in Month',
  103: 'Second week of Month',
  104: 'Third week of Month',
  105: 'Fourth week of Month',
  110: 'Once Only',
}

export const numberEmployeesList = [
  '0-10',
  '10-20',
  '20-50',
  '50-100',
  '100-150',
  '150-300',
  '300-500',
  '+500',
]

export const industryList = [
  '--None--',
  'Consulting',
  'Education',
  'Energy and Utilities',
  'Entertainment',
  'Financial Services',
  'Media and PR',
  'Charity and Not For Profit',
  'Transport and mobility',
  'Other',
  'Recruitment',
  'Legal',
  'Construction/engineering',
  'Retail and consumer',
  'Pharmaceuticals and healthcare',
  'Public sector',
  'Technology and telecoms',
  'Manufacturing',
  'Real estate',
  'Hospitality and events',
]

export const leadList = [
  '--None--',
  'Personal contact',
  'Advertising',
  'Cold call',
  'Direct mail',
  'Email marketing',
  'Search engine',
  'Media article',
  'Driver',
  'Product itself',
  'Trade shows',
  'Vans',
  'Word of mouth',
]

export const dbRegionId = ['DEV', 'MID']

export const defaultTerminationReasons = [
  'Moving Offices and no longer require fruit',
  'Lost our fruit budget',
  'Found a cheaper supplier',
  'Flexibility of service',
  'Quality of fruit worsened',
  'Customer service worsened',
  'Staff want to try something else',
  'Other',
]

export const getEmailFromToken = () => {
  const token = localStorage.getItem('token')
  if (token) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join(''),
    )
    let jsonContent = JSON.parse(jsonPayload)
    return jsonContent.email
  }
  return ''
}

export const getInvoiceCountriesBySystem = (prefix) => {
  switch (prefix) {
    case 'DEV':
    case 'LON':
      return [
        'United Kingdom',
        'Cyprus',
        'Czech Republic',
        'Ireland',
        'Luxembourg',
        'Poland',
        'Portugal',
        'Scotland',
        'Sweden',
        'Switzerland',
      ]
    case 'MID':
      return ['United Kingdom']
    case 'MAN':
      return ['United Kingdom', 'Poland']
    case 'SCO':
      return ['Scotland', 'Philippines']
    default:
      return []
  }
}

export const getWorkplaceActiveOngoingOrder = (workplace) => {
  let activeOrder = null

  const ongoingOrders = _.get(workplace, 'orders', []).filter((order) => {
    return _.isEmpty(order.EndDate)
  })

  _.each(ongoingOrders, (order) => {
    if (activeOrder === null) {
      activeOrder = order
    } else {
      const currentEndDate = moment(order.StartDate)
      const activeEndDate = moment(order.StartDate)

      if (currentEndDate.isAfter(activeEndDate)) {
        activeOrder = order
      }
    }
  })

  return activeOrder
}
