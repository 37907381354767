import { FormControl, FormHelperText, MenuItem, Select, Tooltip } from '@mui/material'
import React from 'react'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import InfoIcon from '@mui/icons-material/Info'

import styles from './selectDropdown.module.scss'

const SelectDropdown = ({
  helperText,
  name,
  helperTextStyle,
  formControlInput,
  selectStyle,
  value,
  handleChange,
  handleOnBlur,
  options,
  adaptWidth,
  menuProps,
  additionalStyling,
  menuItemStyle,
  renderTooltip,
  toolTipText,
  tooltipStyle,
  isDisabled,
}) => {
  const renderTooltipIcon = () => {
    if (!renderTooltip) {
      return null
    }
    return (
      <Tooltip placement="top" title={toolTipText}>
        <InfoIcon className={styles.tooltipStyle} />
      </Tooltip>
    )
  }

  const renderOptions = () =>
    options.length
      ? options.map((option) => (
          <MenuItem key={option} value={option} className={menuItemStyle}>
            {option}
          </MenuItem>
        ))
      : null

  return (
    <>
      <FormHelperText id="outlined-weight-helper-text" className={helperTextStyle}>
        {helperText}
        {renderTooltipIcon()}
      </FormHelperText>
      <FormControl fullWidth size="small" className={formControlInput}>
        <Select
          name={name}
          value={value}
          className={selectStyle}
          onChange={handleChange}
          onBlur={handleOnBlur}
          autoWidth={adaptWidth}
          MenuProps={menuProps}
          sx={additionalStyling}
          disabled={isDisabled}
        >
          {renderOptions()}
        </Select>
      </FormControl>
    </>
  )
}

SelectDropdown.defaultProps = {
  helperText: '',
  name: '',
  helperTextStyle: '',
  formControlInput: '',
  selectStyle: '',
  handleChange: noop,
  handleOnBlur: noop,
  value: '',
  options: [],
  adaptWidth: false,
  menuProps: {},
  additionalStyling: null,
  menuItemStyle: '',
  renderTooltip: false,
  toolTipText: '',
  tooltipStyle: '',
  isDisabled: false,
}

SelectDropdown.propTypes = {
  helperText: PropTypes.string,
  name: PropTypes.string,
  helperTextStyle: PropTypes.string,
  formControlInput: PropTypes.string,
  selectStyle: PropTypes.string,
  handleChange: PropTypes.func,
  handleOnBlur: PropTypes.func,
  value: PropTypes.string,
  options: PropTypes.array,
  adaptWidth: PropTypes.bool,
  menuProps: PropTypes.object,
  additionalStyling: PropTypes.any,
  menuItemStyle: PropTypes.string,
  renderTooltip: PropTypes.bool,
  toolTipText: PropTypes.string,
  tooltipStyle: PropTypes.string,
  isDisabled: PropTypes.bool,
}

export default SelectDropdown
